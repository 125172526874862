import * as React from "react";
import { keyframes, styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { Card, Chip, IconButton, LinearProgress } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { CardActionArea, Link } from "gatsby-theme-material-ui";
import {} from "gatsby";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const indeterminate1Keyframes = keyframes({
  "0%": {
    left: "-35%",
    right: "100%",
  },
  "100%": {
    left: "0%",
    right: "0%",
  },
});

const StyledLinearProgress = styled(LinearProgress)({
  "& .MuiLinearProgress-bar1Indeterminate": {
    width: "auto",
    animation: `${indeterminate1Keyframes} 8s linear forwards`,
  },
  "& .MuiLinearProgress-bar2Indeterminate": {
    display: "none",
  },
});

export const Slider = ({ images }) => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    if (activeStep === maxSteps - 1) {
      setActiveStep(0);
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 0) {
      setActiveStep(maxSteps - 1);
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <Card sx={{ position: "relative", backgroundColor: "transparent" }}>
      {/* <Link to={images[activeStep].slug} sx={{ textDecoration: "none" }}> */}
      <AutoPlaySwipeableViews
        interval={8000}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        disableLazyLoading={true}
      >
        {images.map((step, index) => (
          <CardActionArea key={index} to={images[activeStep].slug}>
            <>
              {Math.abs(activeStep - index) <= 2 ? (
                <>
                  {step.featuredImage}
                  {/* <StyledLinearProgress variant="indeterminate" /> */}
                </>
              ) : null}
            </>
          </CardActionArea>
        ))}
      </AutoPlaySwipeableViews>
      {images.map((i, index) =>
        activeStep === index ? (
          <StyledLinearProgress variant="indeterminate" />
        ) : null
      )}

      <Box
        component={CardActionArea}
        to={images[activeStep].slug}
        sx={{
          p: { xs: 0, sm: 3 },
          pt: { xs: 1 },
          position: { sm: "absolute" },
          top: { sm: 0 },
          left: { sm: 0 },
        }}
      >
        <Chip
          label={images[activeStep].frontmatter.category.toUpperCase()}
          color="primary"
          size="small"
        />
        <Typography
          variant="h4"
          sx={{ color: { xs: "black", sm: "white" }, textDecoration: "none" }}
          fontWeight={"bold"}
        >
          {images[activeStep].frontmatter.title}
        </Typography>
      </Box>

      <MobileStepper
        sx={{
          backgroundColor: "transparent",
          p: { xs: 0, sm: 2 },
          position: { sm: "absolute" },
          bottom: { sm: 0 },
          left: { sm: 0 },
          width: "100%",
        }}
        steps={maxSteps}
        position={"static"}
        activeStep={activeStep}
        nextButton={
          <>
            <IconButton
              onClick={handleNext}
              sx={{
                m: { xs: 0.5 },
                color: { xs: "primary.main", sm: "white" },
                outline: { xs: "1px solid gray", sm: "1px solid white" },
              }}
            >
              <ChevronRight fontSize="large" />
            </IconButton>
          </>
        }
        backButton={
          <IconButton
            onClick={handleBack}
            sx={{
              m: { xs: 0.5 },
              color: { xs: "primary.main", sm: "white" },
              outline: { xs: "1px solid gray", sm: "1px solid white" },
            }}
          >
            <ChevronLeft fontSize="large" />
          </IconButton>
        }
      />
      {/* </Link> */}
    </Card>
  );
};
