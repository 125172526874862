import { Card, CardContent, Box, Chip, Typography } from "@mui/material";
import { CardActionArea } from "gatsby-theme-material-ui";
import React from "react";

export const TextCard = ({ to, category, date, title }) => (
  <Card sx={{ mb: 1 }}>
    <CardActionArea to={to}>
      <CardContent sx={{ padding: "12px !important" }}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          sx={{ mb: 0.75 }}
        >
          <Chip label={category.toUpperCase()} color="primary" size="small" />
          <Box>{date}</Box>
        </Box>
        <Typography fontWeight={"bold"}>{title}</Typography>
      </CardContent>
    </CardActionArea>
  </Card>
);
