import React from "react";
import slugify from "@sindresorhus/slugify";

import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Tabs,
  Tab,
  IconButton,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { Link, Button } from "gatsby-theme-material-ui";
import { navigate } from "gatsby";

export const Header = ({ categories, currentLocation }) => {
  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Link to="/">
            <Typography variant="h5" fontWeight={800}>
              ЧЕРНОМОРСКА СТРАНДЖА
            </Typography>
          </Link>
          <Box flex={1} />
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            {categories.map((i, index) => (
              <Button
                variant={
                  currentLocation === `/${slugify(i)}/`
                    ? "contained"
                    : "outlined"
                }
                disableElevation
                size="small"
                to={`/${slugify(i)}`}
                sx={{
                  mr: 1,
                  ...(currentLocation === `/${slugify(i)}/`
                    ? {
                        color: (theme) => theme.palette.primary.contrastText,
                        border: "none",
                        fontWeight: "bold",
                        bgcolor: "primary.light",
                      }
                    : {
                        color: (theme) => theme.palette.grey[800],
                        borderColor: (theme) => theme.palette.grey[800],
                      }),
                }}
              >
                {i}
              </Button>
            ))}
          </Box>
          {/* <IconButton>
            <Search />
          </IconButton> */}
        </Toolbar>
        <Box sx={{ display: { xs: "block", md: "none" } }}>
          <Tabs
            variant="scrollable"
            scrollButtons={true}
            aria-label="scrollable auto tabs example"
            value={currentLocation}
            allowScrollButtonsMobile
          >
            {categories.map((i, index) => {
              return (
                <Tab
                  onClick={() => navigate(`/${slugify(i)}`)}
                  value={`/${slugify(i)}/`}
                  label={
                    <Typography fontWeight={index === 0 ? "bold" : "initial"}>
                      {i}
                    </Typography>
                  }
                />
              );
            })}
          </Tabs>
        </Box>
      </AppBar>
    </>
  );
};
