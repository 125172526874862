import { Typography } from "@mui/material";
import React from "react";
import { TextCard } from "./TextCard";

export const TextCardStack = ({ articles }) => (
  <>
    <Typography variant="h5" fontWeight={"bold"} mb={1.5}>
      Последни новини
    </Typography>
    {articles.map((article) => (
      <TextCard
        to={article.slug}
        category={article.frontmatter.category}
        date={article.frontmatter.date}
        title={article.frontmatter.title}
      />
    ))}
  </>
);
