import React from "react";

import { Navigation } from "./src/components/Navigation";
import { Footer } from "ui/src/Footer";

export const wrapPageElement = ({ element, props }) => {
  return (
    <>
      <Navigation currentLocation={props.location.pathname} />
      {element}
      <Footer />
    </>
  );
};
