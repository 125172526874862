import React from "react";
import {
  Container,
  Unstable_Grid2 as Grid,
  Typography,
  Card,
  CardContent,
  Box,
  Chip,
  CardMedia,
  Tabs,
  Tab,
  Button,
  // Tab,
  // Tabs,
} from "@mui/material";
import { Slider } from "./Slider";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { CardActionArea } from "gatsby-theme-material-ui";
import { TextCardStack } from "./TextCardStack";

export const IndexPageTemplate = ({
  sliderArticles,
  latestArticles,
  belowSlider,
  moreArticles,
  rightTopBanner,
  underSliderBanner,
  rightBottomBanner,
  categories = [],
  topArticles,
}) => {
  const [firstTopArticle, ...restTopArticles] = topArticles;
  return (
    <Container sx={{ mt: 3 }}>
      <Grid container spacing={3}>
        <Grid xs={12} md={8} container spacing={0}>
          <Typography variant="h5" fontWeight={"bold"} mb={1.5}>
            Важно
          </Typography>
          <Grid xs={12}>
            <Slider images={sliderArticles} />
          </Grid>
          <Grid xs={12} sx={{ display: { xs: "block", sm: "none" } }}>
            {underSliderBanner}
          </Grid>
          {belowSlider.map((i) => (
            <Link to={i.slug} style={{ textDecoration: "none" }}>
              <Card sx={{ mt: 1 }}>
                <CardActionArea sx={{ display: "flex" }}>
                  <CardMedia sx={{ position: "relative" }}>
                    <Box
                      component={GatsbyImage}
                      image={getImage(i.frontmatter.featuredImage)}
                      sx={{ width: { xs: 136, sm: 284 } }}
                    />
                    <Chip
                      label={i.frontmatter.category.toUpperCase()}
                      color="primary"
                      size="small"
                      sx={{ position: "absolute", top: 0, left: 0, m: 1 }}
                    />
                  </CardMedia>
                  <CardContent sx={{ p: { xs: 1, sm: 2 } }}>
                    <Typography
                      fontWeight={"bold"}
                      gutterBottom
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: { xs: 3, sm: 2 },
                        WebkitBoxOrient: "vertical",
                        lineHeight: { xs: 1, sm: 1.5 },
                      }}
                    >
                      {i.frontmatter.title}
                    </Typography>

                    <Typography
                      variant="body2"
                      sx={{ display: { xs: "none", md: "block" } }}
                    >
                      {i.excerpt}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Link>
          ))}
          {/* <Typography variant="h5" fontWeight={"bold"} mb={1.5} mt={3}>
            Топ 5 вести
          </Typography>
          <Card>
            <Tabs
              variant="scrollable"
              scrollButtons={true}
              aria-label="scrollable auto tabs example"
              value={0}
              allowScrollButtonsMobile
            >
              {categories.map((i) => (
                <Tab label={i} value={i} />
              ))}
            </Tabs>
          </Card> */}
          {firstTopArticle && (
            <Grid container spacing={3}>
              <Grid xs={12} sm={6} mt={1.5}>
                <Link
                  to={firstTopArticle?.slug}
                  style={{ textDecoration: "none" }}
                >
                  <Card>
                    <CardActionArea>
                      <CardMedia>
                        <GatsbyImage
                          image={getImage(
                            firstTopArticle?.frontmatter.featuredImage
                          )}
                          alt=""
                        />
                      </CardMedia>
                      <CardContent>
                        {/* <Chip
                        label={firstTopArticle?.frontmatter.category.toUpperCase()}
                        color="primary"
                        size="small"
                      /> */}
                        <Typography
                          gutterBottom
                          fontWeight={"bold"}
                          variant="h6"
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                          }}
                        >
                          {firstTopArticle?.frontmatter.title}
                        </Typography>
                        <Typography variant="body1">
                          {firstTopArticle?.excerpt}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Link>
              </Grid>
              <Grid xs={12} sm={6} mt={0.5}>
                {restTopArticles.map((i) => (
                  <Link to={i.slug} style={{ textDecoration: "none" }}>
                    <Card sx={{ mt: 1 }}>
                      <CardActionArea sx={{ display: "flex" }}>
                        <CardMedia sx={{ position: "relative" }}>
                          <Box
                            component={GatsbyImage}
                            image={getImage(i.frontmatter.featuredImage)}
                            sx={{ width: 136 }}
                          />
                          <Chip
                            label={i.frontmatter.category.toUpperCase()}
                            color="primary"
                            size="small"
                            sx={{ position: "absolute", top: 0, left: 0, m: 1 }}
                          />
                        </CardMedia>
                        <CardContent sx={{ p: 1 }}>
                          <Typography
                            fontWeight={"bold"}
                            gutterBottom
                            sx={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "-webkit-box",
                              WebkitLineClamp: 3,
                              WebkitBoxOrient: "vertical",
                              lineHeight: 1,
                            }}
                          >
                            {i.frontmatter.title}
                          </Typography>

                          {/* <Typography
                          variant="body2"
                          sx={{ display: { xs: "none", md: "block" } }}
                        >
                          {i.excerpt}
                        </Typography> */}
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Link>
                ))}
              </Grid>
              {/* <Grid xs={12}>
              <Button
                variant="contained"
                disableElevation
                sx={{ borderRadius: "50px" }}
              >
                Всички новини от спорт
              </Button>
            </Grid> */}
            </Grid>
          )}
          <Grid xs={12} mt={3}>
            <Typography variant="h5" fontWeight={"bold"} mb={1.5}>
              Повече новини
            </Typography>
          </Grid>
          <Grid container spacing={3}>
            {moreArticles.map((i) => (
              <Grid xs={12} sm={6}>
                <Link to={i.slug} style={{ textDecoration: "none" }}>
                  <Card>
                    <CardActionArea>
                      <CardMedia>
                        <GatsbyImage
                          image={getImage(i.frontmatter.featuredImage)}
                          alt=""
                        />
                      </CardMedia>
                      <CardContent>
                        <Chip
                          label={i.frontmatter.category.toUpperCase()}
                          color="primary"
                          size="small"
                        />
                        <Typography
                          gutterBottom
                          fontWeight={"bold"}
                          variant="h6"
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: 3,
                            WebkitBoxOrient: "vertical",
                          }}
                        >
                          {i.frontmatter.title}
                        </Typography>
                        <Typography variant="body1">{i.excerpt}</Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid xs={12} md={4}>
          {rightTopBanner}
          <TextCardStack articles={latestArticles} />
          {rightBottomBanner}
        </Grid>
      </Grid>
    </Container>
  );
};
