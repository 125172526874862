import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Header } from "ui";

export const Navigation = ({ currentLocation }) => {
  const data = useStaticQuery(graphql`
    query AllCategories {
      allMarkdownRemark(
        filter: {
          frontmatter: {
            category: { nin: ["спорт", "друго", "време", "общински"] }
          }
        }
      ) {
        distinct(field: { frontmatter: { category: SELECT } })
      }
    }
  `);
  return (
    <Header
      categories={data.allMarkdownRemark.distinct}
      currentLocation={currentLocation}
    />
  );
};
