import { createTheme, responsiveFontSizes } from "@mui/material";
import { blue, blueGrey, grey } from "@mui/material/colors";

const theme = createTheme({
  palette: {
    mode: "light",
    primary: { main: blue[900] },
    background: { default: blueGrey[100] },
  },
  shape: { borderRadius: 8 },
  components: {
    MuiAppBar: { defaultProps: { elevation: 0, color: "default" } },
    MuiCard: { defaultProps: { elevation: 0 } },
    MuiChip: {
      styleOverrides: {
        root: ({ ownerState, theme }) => {
          return {
            borderRadius: 4,
            fontWeight: 600,
            ...(ownerState?.color && {
              backgroundColor: theme.palette[ownerState.color].light,
            }),
          };
        },
      },
    },
  },
});

export default responsiveFontSizes(theme);
