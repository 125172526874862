import {
  Facebook,
  Instagram,
  Twitter,
  LinkedIn,
  YouTube,
} from "@mui/icons-material";
import {
  Box,
  Container,
  Unstable_Grid2,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import { Link } from "gatsby";
import React from "react";

export const Footer = () => (
  <Box sx={{ background: "white", pt: 3, pb: 3, mt: 6 }}>
    <Container maxWidth="lg">
      <Unstable_Grid2 container spacing={3}>
        <Unstable_Grid2 xl={4}>
          <Typography variant="h5" fontWeight={"bold"} mb={3} color="primary">
            ЧЕРНОМОРСКА СТРАНДЖА
          </Typography>
          <Typography mb={3}>
            Източник на новини и информация за Царево и околните райони,
            предоставящ актуални статии и новини, свързани с региона и жителите
            му.
          </Typography>
          <Typography>© 2023. Всички права запазени</Typography>
          <Typography>
            <Link to="/privacy-policy">Политика за поверителност</Link>
          </Typography>
          <Typography>
            <Link to="/programen-tekst">Програмен текст</Link>
          </Typography>
        </Unstable_Grid2>
        <Unstable_Grid2 xl={4}>
          {/* <Typography variant="h5" fontWeight={"bold"} mb={3} color="primary">
            БИЛЕТИН
          </Typography>
          <Typography mb={3}>
            Добијте ги најважните Вестимак вести секоја утро во вашата е-пошта.
          </Typography>
          <TextField size="small" sx={{ mr: 2 }} />
          <Button variant="contained">ЗАПИШИ СЕ</Button> */}
        </Unstable_Grid2>
        <Unstable_Grid2 xl={4}>
          <Typography variant="h5" fontWeight={"bold"} mb={3} color="primary">
            Социални мрежи
          </Typography>
          <Typography mb={3}>
            Следвайте @chernomorskastrandzha за последните новини, събития и
            политика от Царево и региона.
          </Typography>
          <Link
            to="https://www.facebook.com/chernomorskastrandzha"
            target={"_blank"}
          >
            <Facebook color="primary" fontSize="large" sx={{ mr: 3 }} />
          </Link>
          {/* <Instagram color="primary" fontSize="large" sx={{ mr: 3 }} />
          <Twitter color="primary" fontSize="large" sx={{ mr: 3 }} />
          <LinkedIn color="primary" fontSize="large" sx={{ mr: 3 }} />
          <YouTube color="primary" fontSize="large" sx={{ mr: 3 }} /> */}
        </Unstable_Grid2>
      </Unstable_Grid2>
      Всички статии, снимки и произведения на този сайт са собственост на
      Черноморска Странджа. Използването им без писмено разрешение е забранено.
    </Container>
  </Box>
);
